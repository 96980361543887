
import { Vuex, createStore } from 'vuex'
import router from '../router/index.js'
import jwt_decode from "jwt-decode"
import api from "@/api";

// Make Axios play nice with Django CSRF
api.defaults.xsrfCookieName = 'csrftoken';
api.defaults.xsrfHeaderName = 'X-CSRFToken';

// INIT State
const getDefaultState = () => {
	return {
		status: '',
		username: '',
		firstName: '',
		isAdmin: false,
		isAuthenticated: false,
		showLoginModal: false,
		dataLoaded: false,
		generalContent: null,   
		generalContentAll: null,
		customContentAll: null, 
		customContent: null,
		cardTypes: null,
		user_info: null,
		users: null,
		languages: [],
		generalContentFields: [],
		customContentFields: [],
		lastRefresh: 0,
	}
}

export default createStore({
	state: getDefaultState(),
	mutations: {
		updateToken(state, token){
		  localStorage.setItem('token', JSON.stringify(token));
		},
		setIsAuthenticated(state, isAuthenticated ) {
			state.isAuthenticated = isAuthenticated;
		},
		resetState(state){
			Object.assign(state, getDefaultState() )
		},
		openLoginModal(state){
			state.showLoginModal = true;
		},
		closeLoginModal(state){
			state.showLoginModal = false;
		},
		openLoginModal(state){
			state.showLoginModal = true;
		},
		setDataLoaded(state) {
           state.dataLoaded = true 
		}, 
		setGeneralContent(state, payload) {    
           state.generalContent = payload 
		}, 
		setGeneralContentAll(state, payload) {    
           state.generalContentAll = payload 
		}, 
		setCustomContentAll(state, payload) {    
           state.customContentAll = payload 
		}, 
		setCustomContent(state, payload) {
           state.customContent = payload 
		}, 
		setCardTypes(state, payload) {
           state.cardTypes = payload 
		}, 
		setUsers(state, payload) {
           state.users = payload 
		},  
		setUserInfo(state, payload) {
           state.user_info = payload 
		}, 
		setIsAdmin(state, payload) {
           state.isAdmin = payload    
		}, 
		setUsername(state, payload) {
           state.username = payload 
		}, 
		setFirstName(state, payload) {
           state.firstName = payload 
		}, 
		setLanguages(state, payload) { 
           state.languages = payload 
		}, 
		setGeneralContentFields(state, payload) {
           state.generalContentFields = payload 
		}, 
		setCustomContentFields(state, payload) {
           state.customContentFields = payload 
		}, 
	},
	actions: {
		//#--------------------------------------------------------------- 
		//# JWT Auth
		//#--------------------------------------------------------------- 
		//
		async obtainToken(context, loginData) {
			let vm = this;
			
			const payload = {
				username: loginData.username,
				password: loginData.password
			}
			
			const url = '/auth/obtain_token/'
			let success = await api.post(url, payload)
				.then((response) => {
					//console.log('obtainToken',response.data)
					vm.commit('updateToken', response.data)
					vm.commit("setIsAuthenticated", true )
					return true
				})
				.catch((error) => {
					console.log('error',error)
					window.alert(vm.$t('message.incorrectPassword'))
					return false
				})
			
			if(success){
				router.replace('/home')
			}


		},
		async refreshToken() {
			let vm = this;
			
			const token = JSON.parse(localStorage.getItem('token'))

			if (token) {
				const payload = { refresh: token.refresh }

				return await api.post('/auth/refresh_token/', payload)
					.then((response) => {
						//console.log('refreshToken',response.data)
						vm.commit('updateToken', response.data)
						vm.commit("setIsAuthenticated", true )
						return response.data
					})
					.catch((error) => {
						//console.log('refresh_response',error)
						vm.dispatch('logout', true)
						return false
				})
			}else{
				return false
			}

		},
		//#--------------------------------------------------------------- 
		//# Logout
		//#--------------------------------------------------------------- 
		//
		logout(context, alert) {
			let vm = this;
			vm.commit("setIsAuthenticated", false)

			localStorage.removeItem('token');
			vm.state.isAuthenticated = false;
			
			vm.commit('resetState')
			localStorage.clear()
			if (alert) {
				// alert only after eveyyting is cleaned up
				vm.commit("openLoginModal")
			}
			router.push('/auth')
			
		},
		//#--------------------------------------------------------------- 
		//# MAIN ACTIONS
		//#--------------------------------------------------------------- 
		//
		async getUserData(context){ 
			let vm = this;

			const token = await vm.dispatch('getToken');

			const url = '/v1/content_data/'
			await api.get(url, {headers: {Authorization: `Bearer ${token.access}`}})
			.then((response)=>{
				context.commit('setGeneralContent',response.data.general_content)
				context.commit('setCustomContent',response.data.custom_content)
				context.commit('setCardTypes',response.data.card_types)
				context.commit('setIsAdmin',response.data.is_admin) 
				context.commit('setUsername',response.data.username) 
				context.commit('setFirstName',response.data.first_name) 
				context.commit('setUserInfo',response.data.user_info) 
				
				if(response.data.is_admin === true){
					console.log('getUserData', response.data) 
					context.commit('setUsers',response.data.users)
					context.commit('setGeneralContentAll',response.data.general_content_all) 
					context.commit('setCustomContentAll',response.data.custom_content_all) 
					context.commit('setLanguages',response.data.languages)
					context.commit('setGeneralContentFields',response.data.general_content_fields)
					context.commit('setCustomContentFields',response.data.custom_content_fields)
				}
				context.commit('setDataLoaded')
			})
			.catch((error)=>{
				console.log(error)
			})
		},
		//#--------------------------------------------------------------- 
		//# Utils
		//#--------------------------------------------------------------- 
		//
		async getToken(){
			let token = await this.dispatch('refreshToken') 
			return token
		},
	},
})
