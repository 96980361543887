<template>
    <div class="main-title d-flex justify-content-center noselect">
      
    </div>
</template>

<script>

export default {
  name: "ScHeader",
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-title{
  font-family:Monospace;
  padding-top: 35px;
  font-size: 70px;
}

@media only screen and (max-width:400px) {
  .main-title{
    padding-top: 20px;
    font-size: 35px;
  }
}


</style>

