<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper d-flex justify-content-center align-items-center py-5 " >
        <div class="card w-35">
          <article class="card-body">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h4 class="card-title text-center mb-2 mt-1">{{ $t("message.Connexion") }}</h4>
              <div class="pr-3">
                <!-- CLOSE BUTTON X -->
                <a 
                  v-if="false"
                  class="modal-close"
                  @click="closeModal()"
                  style="cursor: pointer;"
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="$t('message.Close')"
                >
                  <span style="font-size: 30">
                    <i class="fas fa-times"></i>
                  </span>
                </a>
              </div>
            </div>
            <hr class="m-3">
            <!--<p class="text-success text-center">Some message goes here</p>-->
            <form>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text"> <i class="fa fa-user"></i> </span>
                  </div>
                  <input required name="" class="form-control" type="text" v-model="username" :placeholder="$t('message.Username')" autofocus @keyup.enter="Login()">
                </div> <!-- input-group.// -->
              </div> <!-- form-group// -->
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                  </div>
                    <input required class="form-control" type="password" v-model="password" :placeholder="$t('message.Password')" @keyup.enter="Login()">
                </div> <!-- input-group.// placeholder="******"  -->
              </div> <!-- form-group// -->
              <div class="form-group">
                <button class="btn btn-primary btn-block" @click="Login()"> {{ $t("message.Login") }}  </button>
              </div> <!-- form-group// -->
            </form> 
          </article>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>

export default {
  name: "LoginModal",
  data() {
    return {
      username: null,
      password: null,
    };
  },
  methods:{
    async Login(){
        let vm = this
        await this.$store.dispatch('obtainToken',{'username': this.username,'password': this.password.toLowerCase()}).then(() => {
          vm.$router.push('/home')
        })
    },
    closeModal() {
      this.$emit("close-modal");
    },

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

