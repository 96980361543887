<template>          
  <div class="main-card noselect">
    <div class="main-card-header">
      <span>{{ $t(data.card_title) }}</span>
    </div>
      
    <div class="main-card-body">
        
        <div class="wrapper">

          <div class="form-group noselect">
            <label for="exampleFormControlSelect1">{{ $t('message.editUser') }}</label>
            <select class="form-control" v-model="activeUser">
              <option :value="user"  v-for="(user, index) in sortedUsers" class="select-user slide d-flex justify-content-center mb-4" :key="index">{{ user.username }}</option>
            </select>
          </div>

         
          <div class="wrapper" v-if="activeUser.username">
            <hr>
          
            <div class="d-flex flex-row justify-content-between">
              <div>
                <label class="mt-2" for="exampleFormControlSelect1">{{ $t('message.firstname') }}</label>
                <input type="text" class="form-control mr-1" v-model="activeUser.first_name" :placeholder="$t('message.firstname')"> 
              </div>
              <div>
                <label class="mt-2" for="exampleFormControlSelect1">{{ $t('message.email') }}</label>
                <input type="email" class="form-control" v-model="activeUser.email" :placeholder="$t('message.email')"> 
              </div>
            </div>

            <div class="d-flex flex-row justify-content-between">
              <div>
                <label class="mt-2" for="exampleFormControlSelect1">{{ $t('message.numberGuests') }}</label>
                <input type="text" class="form-control mr-1" v-model="activeUser.number_guests" :placeholder="$t('message.numberGuests')"> 
              </div>
              <div>
                <label class="mt-2" for="exampleFormControlSelect1">{{ $t('message.numberChildren') }}</label>
                <input type="email" class="form-control" v-model="activeUser.number_children" :placeholder="$t('message.numberChildren')"> 
              </div>
            </div>

            <label class="mt-2" for="exampleFormControlSelect1">{{ $t('message.Language') }}</label>
            <select class="form-control" v-model="activeUser.lang"> 
              <option v-for="(lang, index) in $store.state.languages" :key="index" :value="lang" class="select-language slide d-flex justify-content-center mb-4" >{{ lang }}</option>
            </select>

            <div class="form-group mt-2" v-if="activeUser.username !== 'admin'">
              <label class="custom-control custom-checkbox">
              <input type="checkbox" value="2" name="chbxSuperUser" class="custom-control-input" v-model="activeUser.is_superuser">
              <span class="custom-control-label" for="chbxSuperUser">{{ $t('message.administrator') }}</span>
              </label>
            </div>

            <div class="form-group mt-2">
              <label class="custom-control custom-checkbox">
              <input type="checkbox" value="2" name="chbxStandesamt" class="custom-control-input" v-model="activeUser.standesamt">
              <span class="custom-control-label" for="chbxStandesamt">{{ $t('message.mariageCivil') }}</span>
              </label>
            </div>

            <div class="form-group">
              <label class="custom-control custom-checkbox">
              <input type="checkbox" value="2" name="chbxFreieTrauung" class="custom-control-input" v-model="activeUser.freie_trauung">
              <span class="custom-control-label" for="chbxFreieTrauung">{{ $t('message.ceremonieLaique') }}</span>
              </label>
            </div> 

            <!-- Cancel - Submit -->
            <div class="d-flex flex-row justify-content-end">
              <button class="btn btn-secondary mb-2 mr-2" @click="Cancel()">{{ $t('message.Cancel') }}</button>
              <button class="btn btn-primary mb-2" @click="updateUser({changePassword: false})">{{ $t('message.Save') }}</button>
            </div>

            <hr>

            <div class="form-group mt-3">
              <form autocomplete="off">
                <label for="exampleFormControlSelect1">{{ $t('message.EnterPassword') }}</label>
                <input type="password" class="form-control" autocomplete="off" v-model="activeUser.password_1" :placeholder="$t('message.EnterPassword')">
              </form>
            </div>
            <div class="form-group mt-3">
              <form autocomplete="off">
                <label for="exampleFormControlSelect1">{{ $t('message.ReEnterPassword') }}</label>
                <input type="password" class="form-control" autocomplete="off" v-model="activeUser.password_2" :placeholder="$t('message.ReEnterPassword')">
              </form>
            </div>

            <!-- Cancel - Submit -->
            <div class="d-flex flex-row justify-content-end">
              <button class="btn btn-secondary mb-2 mr-2" @click="Cancel()">{{ $t('message.Cancel') }}</button>
              <button class="btn btn-primary mb-2" @click="updateUser({changePassword: true})">{{ $t('message.changePassword') }}</button>
            </div>

          </div>
        </div>

        <hr>
        
        <div class="wrapper">
            <label >{{ $t('message.createUser') }}</label>
            <div class="form-group ">
              <div class="wrapper">
                <div class="d-flex flex-row justify-content-between">
                  <input type="text" class="form-control mr-1" v-model="newUser.first_name" :placeholder="$t('message.firstname')"> 
                  <input type="text" class="form-control mr-1" v-model="newUser.username" :placeholder="$t('message.username')"> 
                  <input type="email" class="form-control" v-model="newUser.email" :placeholder="$t('message.email')"> 
                </div>

                <div class="d-flex flex-row justify-content-between">
                  <input type="text" class="form-control mr-1" v-model="newUser.number_guests" :placeholder="$t('message.numberGuests')"> 
                  <input type="text" class="form-control mr-1" v-model="newUser.number_children" :placeholder="$t('message.numberChildren')"> 
                </div>

                <label class="mt-2" for="exampleFormControlSelect1">{{ $t('message.Language') }}</label>
                <select class="form-control" v-model="newUser.lang"> 
                  <option v-for="(lang, index) in $store.state.languages" :key="index" :value="lang" class="select-language slide d-flex justify-content-center mb-4" >{{ lang }}</option>
                </select>

                <div class="form-group mt-2">
                  <label class="custom-control custom-checkbox">
                  <input type="checkbox" value="2" name="chbxTerms" class="custom-control-input" v-model="newUser.standesamt">
                  <span class="custom-control-label" for="chbxTerms">{{ $t('message.mariageCivil') }}</span>
                  </label>
                </div>

                <div class="form-group">
                  <label class="custom-control custom-checkbox">
                  <input type="checkbox" value="2" name="chbxTerms" class="custom-control-input" v-model="newUser.freie_trauung">
                  <span class="custom-control-label" for="chbxTerms">{{ $t('message.ceremonieLaique') }}</span>
                  </label>
                </div> 
                
                <div class="form-group mt-3">
                    <form autocomplete="off">
                      <label for="exampleFormControlSelect1">{{ $t('message.EnterPassword') }}</label>
                      <input type="password" class="form-control" autocomplete="off" v-model="newUser.password_1" :placeholder="$t('message.EnterPassword')">
                    </form>
                  </div>
                  <div class="form-group mt-3">
                    <form autocomplete="off">
                      <label for="exampleFormControlSelect1">{{ $t('message.ReEnterPassword') }}</label>
                      <input type="password" class="form-control" autocomplete="off" v-model="newUser.password_2" :placeholder="$t('message.ReEnterPassword')">
                    </form>
                  </div>
              </div>
            </div>
            <!-- Cancel - Submit -->
            <div class="d-flex flex-row justify-content-end">
              <button class="btn btn-secondary mb-2 mr-2" @click="Cancel()">{{ $t('message.Cancel') }}</button>
              <button class="btn btn-primary mb-2" @click="createUser()">{{ $t('message.createUser') }}</button>
            </div>
        </div>

        <hr >

        <!-- Quick Import -->
        <div class="wrapper">
            <label for="exampleFormControlSelect1">{{ $t('message.quickUserImport') }}</label>
            <div class="custom-file">
              <label class="custom-file-label" for="customFile">{{ $t('message.ChooseFile') }}</label>
              <input id="customFile" ref="imageUploader" class="custom-file-input" type="file"  @click="resetFileUploader()" @change="uploadFile( $event )">
            </div>   
        </div>  
        
        
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "CardUserAdmin", 
  props: {
    data: Object,
  },
  data() {
    return {
      activeUser: {
        first_name: null,
        username: null,
        email: null,
        lang: null,
        freie_trauung: false, 
        content_admin: false, 
        username: null,
        password_1: null,
        password_2: null,
      },
      newUser:{
        first_name: null,
        username: null,
        email: null,
        lang: null,
        standesamt: false, 
        freie_trauung: false, 
        content_admin: false, 
        password_1: null,
        password_2: null,
      },
      file: null,
      show: false,
    };
  },
  computed:{
    sortedUsers(){
      let state_users = this.$store.state.users
      return state_users.sort((a, b) => a.username.localeCompare(b.username))
    }
  },
  mounted(){
    this.show = true
  },
  methods:{
    resetFileUploader() {
        this.$refs.imageUploader.value = '';
    },
    async uploadFile( event ){
      let vm = this
      this.file = null
      this.file = event.target.files[0];
      let formData = new FormData();
      formData.append('file', this.file );
      formData.append('description', 'quick_import');

      const token = await this.$store.dispatch('getToken');	
      
			const url = '/v1/quick_import/'
			await api.post(url, formData, 
				{headers: {
          Authorization: `Bearer ${token.access}`,
          'Content-Type': 'multipart/form-data'
        }})

				.then((response)=>{
					// console.log(response)
				  window.alert(vm.$t('message.UploadSuccessful'))
				})
				.catch((error)=>{
					// console.log(error)
				})

    },
    ValidateEmail(email){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    async updateUser(mode){
        let req = 'put'
        let url = `/v1/user/`
        let payload = this.activeUser
        if(!mode.changePassword ){
            payload.change_password = false
        }else{
            payload.change_password = true
        }
        let response = await this.sendRequest(req, url, payload)

        if(response === 'success'){
          window.alert(this.$t('message.SaveSuccessful'))
          window.scrollTo({top: 0, behavior: 'smooth'});
          
        }else{
          window.alert(this.$t('message.Error'))
          window.scrollTo({top: 0, behavior: 'smooth'});
      }

    },
    async createUser(){
        let vm = this;

        if(!this.ValidateEmail(vm.newUser.email)){
          alert("L'adresse email est invalide !")
          return
        }

        if(vm.newUser.password_1 !== vm.newUser.password_2 ){
          alert("Les mots de passe ne correspondent pas")
          return
        }

        let req = 'post'
        let url = `/v1/user/`
        let payload = this.newUser
        let response = await this.sendRequest(req, url, payload)

        if(response === 'success'){
          window.alert(this.$t('message.SaveSuccessful'))
          window.scrollTo({top: 0, behavior: 'smooth'});
          
        }else{
          window.alert(this.$t('message.Error'))
          window.scrollTo({top: 0, behavior: 'smooth'});
      }

    },
    Cancel(){
      this.activeUser = {
        first_name: null,
        username: null,
        email: null,
        lang: null,
        freie_trauung: false, 
        content_admin: false, 
        password_1: null,
        password_2: null,
      }

      this.newUser = {
        first_name: null,
        username: null,
        email: null,
        lang: null,
        freie_trauung: false, 
        content_admin: false, 
        password_1: null,
        password_2: null,
      }

      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    async sendRequest(req, url, payload){
      const token = await this.$store.dispatch('getToken');	
      
			let response = await api[req](url, payload, 
				{headers: {Authorization: `Bearer ${token.access}`}})

				.then((response)=>{
					// console.log(response)
          return 'success'
				})
				.catch((error)=>{
					// console.log(error)
          return 'failed'
				})
        
      return response
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-card{
  background: #FFF8EF;
  border-radius: 25px;
  padding: 20px;
  width: 800px;
  min-height: 500px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.main-card-header{
  font-family: 'Open Sans';
  font-size: 40px;
  text-align: center;
  height: 80px;
  color: #135663;
}

.main-card-body{
   height: 80%;
   padding-top:15px;
}

@media only screen and (max-width:400px) {

  .main-card{
    margin: 25px;
    margin-top: 15px;
    padding: 10px;
  }

  .main-card-header{
    font-size: 25px;
    height: 50px;
  }

  .main-card-body{
    background-size: 150px;
  }

}

.main-card-header::after {
  content: "";
  display: block;
  border-bottom: 2px solid #135663;
  width: 50%;
  position: relative;
  margin: auto;
  bottom: -6px; /* your padding + border-width */
}


</style>

