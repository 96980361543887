<template>
    <div>
      404
    </div>
</template>

<script>
import api from "@/api";

export default {
  name: "PageNotFound",
  components: {
  },
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

