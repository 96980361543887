<template>
    <div class="d-flex align-items-end justify-content-center noselect">
      v1.0 Copyright© {{ new Date().getFullYear() }} &nbsp; <a class="hyperlink" @click="$store.dispatch('logout',true)">Logout</a>
    </div>
</template>

<script>

export default {
  name: "ScFooter",
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

