<template>          
  <div class="main-card noselect" :ref="'card'+data.card_id">
    <div class="main-card-header ">
      <span>{{ $t(data.card_title) }}</span>
    </div>
    <!-- Content card -->
    <div v-if="data.content" class="content-wrapper mt-3">
      <div class="card-content" v-html="content_no_paragraph(data.content)"></div>
    </div>

    <!-- Picture Gallery Standesamt -->
    <div v-if="data.card_id == 0" class="content-wrapper mt-3">
      <hr v-if="data.content">
      <label for="iFrameOneDrive">{{ $t('message.pictureGallery') }}</label>

      <div class="iframe-conatiner d-flex flex-row justify-content-center mb-3">
        <iframe 
          src="https://onedrive.live.com/embed?cid=6FAE299D68D176C2&resid=6FAE299D68D176C2%211465&authkey=AFU8FNIs7n2jzEs" 
          width="165" 
          height="128" 
          frameborder="0" 
          scrolling="no">
        </iframe>
      </div>
    </div>

    <!-- survey -->
    <div v-if="data.card_id == 1" class="content-wrapper mt-3">
      <hr>
      <div class="wrapper">

        <div class="form-group mt-2">
          <label class="custom-control custom-checkbox">
          <input type="checkbox" value="2" name="chbxStandesamt" class="custom-control-input" v-model="$store.state.user_info.wedding_present" @click="updateUser()">
          <span class="custom-control-label" for="chbxStandesamt">{{ $t('message.WeddingPresent') }}</span>
          </label>
        </div>
        
        <div class="form-group mt-2">
          <label class="custom-control custom-radio">
          <input type="radio" id="one" :value="true" class="custom-control-input" v-model="$store.state.user_info.accomodation_cared" @click="updateUser()"/>
         <span class="custom-control-label" for="chbxStandesamt">{{ $t('message.AccomodationCared') }}</span>
          </label>
        </div>

        <div class="form-group mt-2">
          <label class="custom-control custom-radio">
          <input type="radio" id="two" :value="false" class="custom-control-input" v-model="$store.state.user_info.accomodation_cared" @click="updateUser()"/>
          <span class="custom-control-label" for="chbxStandesamt">{{ $t('message.AccomodationSelf') }}</span>
          </label>
        </div>

      </div>
    </div>

    <div v-if="data.customContent" class="content-wrapper">
      <hr>
      <div class="card-content mt-3" v-html="content_no_paragraph(data.customContent)"></div>
    </div>

    <div v-if="data.card_id == 1" class="content-wrapper mt-3">
      <hr>
      <!-- Music Content -->
      <div class="form-group">
        <label class="mb-3" for="exampleFormControlTextarea1">{{ $t('message.MusicChoice') }}</label>
        <QuillEditor 
          @ready="onEditorReady('editor',$event); setContent('editor')" 
          @update:content="updateContent('editor')" 
          @mousedown="mouseDown()"
          @mouseup="mouseUp()"
          content-type="html" 
          :toolbar="toolbar" 
          theme="snow" />
      </div>

    </div>


  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "Card",
  props: {
    data: Object,
  },
  data() {
    return {
      isMounted: false,
      editor: null,
      //toolbar: false,
      toolbar: {
        container: [
              ['bold', 'italic', 'underline'], // toggled buttons
              //[{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ list: 'bullet' }],
              //[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              //[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
              //[{ 'color': ["#135663", "#993300","#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']
              // }, {'background': []}], // dropdown with defaults from theme
              //[{ align: [] }],
              //['link'],
          ], 
       }
    }
  },
  computed:{
    accomodation_cared(){
      return this.$store.state.accomodation_cared
    },
    not_accomodation_cared(){
      return !this.$store.state.accomodation_cared
    },
  },
  mounted(){
    this.isMounted = true
    this.matchHeight()

  },
  methods:{
    content_no_paragraph(content){
      let return_value = content.replace(/<p>|_/g,'<div>')
      return_value = return_value.replace(/<\/p>|_/g,'</div>')
      return  return_value
    },
    matchHeight () {
      let height = this.$refs['card'+this.data.card_id].clientHeight;

      if(height > 0 ){
        this.$refs['card'+this.data.card_id].setAttribute("style",`height:${height+300}px`);
      }
    },
    mouseDown(){
      this.$emit('editor-mouse-down')
    },
    mouseUp(){
      this.$emit('editor-mouse-up')
    },
    onEditorReady(editor,event) {
      this[editor]=event
    },
    setContent(editor){
        this[editor].container.querySelector('.ql-editor').innerHTML = this.$store.state.user_info.music_choice
    },
    updateContent(editor){
        this.$store.state.user_info.music_choice = this[editor].container.querySelector('.ql-editor').innerHTML
        this.updateUser()
    },
    async updateUser(){
        let req = 'put'
        let url = `/v1/user_data/`
        let payload = this.$store.state.user_info
        let response = await this.sendRequest(req, url, payload)
    },
    async sendRequest(req, url, payload){
      const token = await this.$store.dispatch('getToken');	
      
			let response = await api[req](url, payload, 
				{headers: {Authorization: `Bearer ${token.access}`}})

				.then((response)=>{
					// console.log(response)
          return 'success'
				})
				.catch((error)=>{
					// console.log(error)
          return 'failed'
				})
        
      return response
    },
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.card-content{
  white-space: pre-wrap;
}


.main-card{
  background: #FFF8EF;
  border-radius: 25px;
  padding: 20px;
  width: 800px;
  min-height: 200px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  background-image: url("~@/assets/img/flower-bg-rev.png");
  background-size: 300px;
  background-position: right bottom; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  background-origin: content-box;
}

.main-card-header{
  font-family: 'Open Sans';
  font-size: 40px;
  text-align: center;
  height: 80px;
  color: #135663;
}


@media only screen and (max-width:400px) {

  .main-card{
    margin: 25px;
    margin-top: 15px;
    padding: 10px;
    background-size: 150px;
  }

  .main-card-header{
    font-size: 25px;
    height: 50px;
  }

}

.main-card-header::after {
  content: "";
  display: block;
  border-bottom: 2px solid #135663;
  width: 50%;
  position: relative;
  margin: auto;
  bottom: -6px; /* your padding + border-width */
}

.ql-toolbar {
  display: none;
}

</style>

