<template>          
  <!-- eslint-disable-next-line vue/no-v-model-argument -->
  <div class="main-card noselect">
    <div class="main-card-header">
      <span>{{ $t(data.card_title) }}</span>
    </div>
      
    <div class="main-card-body">
      <form>
      <!-- General Content -->
        <div class="form-group d-flex flex-row justify-content-between">
          <div class="wrapper">
            <label for="exampleFormControlSelect1">{{ $t('message.ContentType') }}</label>
            <select class="form-control" @change="setUserGeneralContent()" v-model="selectedCard">
              <option v-for="(type, index) in cardTypes" :key="index" :value="{ id: type.id, text: type.card_title }"  class="select-general-content slide d-flex justify-content-center mb-4" >{{ $t(type.card_title) }}</option>
            </select>
          </div>

          <div class="wrapper">
            <label for="exampleFormControlSelect1">{{ $t('message.Language') }}</label>
            <select class="form-control" @change="setUserGeneralContent()" v-model="selectedLanguage"> 
              <option v-for="(lang, index) in $store.state.languages" :key="index" :value="{ id: 'lang'+index, text: lang }" class="select-language slide d-flex justify-content-center mb-4" >{{ lang }}</option>
            </select>
          </div>
        </div>
        
        <div class="wrapper">
          <hr >

          <div class="form-group">
            <label for="exampleFormControlTextarea1">{{ $t('message.generalInput') }}</label>
            <QuillEditor 
              @ready="onEditorReady('editor1',$event)" 
              @update:content="updateContent('editor1', 'userGeneralContent')" 
              @mousedown="mouseDown()"
              @mouseup="mouseUp()"
              content-type="html" 
              :toolbar="toolbar" 
              theme="snow" />
            
          </div>
          
          <hr>

          <div  class="form-group">
            <label for="exampleFormControlSelect1">{{ $t('message.selectUserCustomInput') }}</label>
            <select class="form-control" @change="setUserCustomContent()" v-model="selectedUser">
              <option :value="{id: user.user_id, value: user.username }"  v-for="(user, index) in $store.state.users" class="select-user slide d-flex justify-content-center mb-4" :key="index">{{ user.username }}</option>
            </select>
          </div>

          <div class="wrapper" v-if="selectedUser">

            <!-- Custom Content -->
            <div class="form-group">
              <label for="exampleFormControlTextarea1">{{ $t('message.CustomInput') }}</label>
              <QuillEditor 
                @ready="onEditorReady('editor2',$event); setContent('editor2', 'userCustomContent')" 
                @update:content="updateContent('editor2', 'userCustomContent')" 
                @mousedown="mouseDown()"
                @mouseup="mouseUp()"
                content-type="html" 
                :toolbar="toolbar" 
                theme="snow" />
            </div>
          </div>

          <!-- Cancel - Submit -->
          <div class="d-flex flex-row justify-content-end">
            <button class="btn btn-secondary mb-2 mr-2" @click="Cancel()">{{ $t('message.Cancel') }}</button>
            <button class="btn btn-primary mb-2" @click="Submit()">{{ $t('message.Save') }}</button>
          </div>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "CardContentAdmin", 
  props: {
    data: Object,
  },
  data() {
    return {
      selectedCard: null,
      selectedUser: null,
      selectedLanguage: null,
      userGeneralContent: {}, 
      userCustomContent: {},
      show: false,
      editor1: null,
      editor2: null,
      toolbar: {
        container: [
              ['bold', 'italic', 'underline'], // toggled buttons
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ 'color': ["#135663", "#993300","#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']}], // dropdown with defaults from theme
              [{ align: [] }],
              ['link'],
          ],  
       }
    };
  },
  mounted(){
    if(this.$store.state.isAdmin){
      this.selectedCard = {id: 0, text: this.$store.state.cardTypes[0].card_title }
      this.selectedLanguage  = {id: 'lang0', text: 'fr' }
      this.setUserGeneralContent()
      this.editor1.container.querySelector('.ql-blank').innerHTML = this.userGeneralContent.content
    }
    this.show = true
  },
  computed:{
    cardTypes(){
      return this.$store.state.cardTypes.filter(e => (e.card_type === 'standesamt' || e.card_type === 'freie_trauung'))
    },
  },
  methods:{
    mouseDown(){
      this.$emit('editor-mouse-down')
    },
    mouseUp(){
      this.$emit('editor-mouse-up')
    },
    onEditorReady(editor,event) {
      this[editor]=event
    },
    setContent(editor,dataType){
        this[editor].container.querySelector('.ql-editor').innerHTML = this[dataType].content
    },
    updateContent(editor,dataType){
        this[dataType].content = this[editor].container.querySelector('.ql-editor').innerHTML
    },
    cleanupQuillContent(content){
      if(content === '<p><br></p>'){
        return ''
      }else{
        return content
      }
    },
    setUserGeneralContent(){
      this.selectedUser = false
      let data = this.$store.state.generalContentAll.filter(e => e.card_id === this.selectedCard.id).filter(e => e.lang === this.selectedLanguage.text)[0] 

      if(!data){ data = []}
      for( let field of this.$store.state.generalContentFields){
        if(field in data){
          this.userGeneralContent[field] = data[field]
        }else{
          this.userGeneralContent[field] = null
        }
      }
      
      this.setContent('editor1', 'userGeneralContent')

    }, 
    setUserCustomContent(){
      let data = this.$store.state.customContentAll.filter(e => e.user_id === this.selectedUser.id).filter(e => e.card_id === this.selectedCard.id)[0] 
      
      if(!data){ data = []}
      for( let field of this.$store.state.customContentFields){
        if(field in data){
          this.userCustomContent[field] = data[field]
        }else{
          this.userCustomContent[field] = null
        }
      }

      if(this.editor2){
        this.setContent('editor2', 'userCustomContent')
      }

    },
    async Submit(){
      let response = ''
      let req = ''
      let payload = {}
      let url = ''
      // Quill cleanup for empty editor
      this.userGeneralContent.content = this.cleanupQuillContent(this.userGeneralContent.content)

      if(this.userGeneralContent.status === 'new'){
        req = 'put'
        url = `/v1/general_content_detail/${this.selectedLanguage.text}/${this.selectedCard.id}/`
        payload = this.userGeneralContent
        response = await this.sendRequest(req, url, payload)

      }else{
        req = 'post'
        url = `/v1/general_content_detail/${this.selectedLanguage.text}/${this.selectedCard.id}/`
        this.userGeneralContent.card_id = this.selectedCard.id
        this.userGeneralContent.lang = this.selectedLanguage.text
        payload = this.userGeneralContent
        response = await this.sendRequest(req, url, payload)

      }

      if(this.selectedUser){
          // Quill cleanup for empty editor
          this.userCustomContent.content = this.cleanupQuillContent(this.userCustomContent.content)

          this.userCustomContent.user_id = this.selectedUser.id
          this.userCustomContent.card_id = this.selectedCard.id
          url = `/v1/custom_content_detail/`
          payload = this.userCustomContent

        if(this.userCustomContent.status === 'new'){
          req = 'put'
          response = await this.sendRequest(req, url, payload)

        }else{
          req = 'post'
          response = await this.sendRequest(req, url, payload)
        }
        
      }
    
      if(response === 'success'){
        window.alert(this.$t('message.SaveSuccessful'))
        await this.$store.dispatch('getUserData');
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setUserGeneralContent()
      }else{
        window.alert(this.$t('message.Error'))
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    Cancel(){
      this.setUserGeneralContent()
      if(this.selectedUser){
        this.setUserCustomContent()
      }

      window.alert(this.$t('message.CancelSuccessful'))
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    async sendRequest(req, url, payload){
      const token = await this.$store.dispatch('getToken');	
      
			let response = await api[req](url, payload, 
				{headers: {Authorization: `Bearer ${token.access}`}})

				.then((response)=>{
					// console.log(response)
          return 'success'
				})
				.catch((error)=>{
					// console.log(error)
          return 'failed'
				})
        
      return response
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-card{
  background: #FFF8EF;
  border-radius: 25px;
  padding: 20px;
  width: 800px;
  min-height: 500px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.main-card-header{
  font-family: 'Open Sans';
  font-size: 40px;
  text-align: center;
  height: 80px;
  color: #135663;
}

.main-card-body{
   height: 80%;
   padding-top:15px;
}

@media only screen and (max-width:400px) {

  .main-card{
    margin: 25px;
    margin-top: 15px;
    padding: 10px;
  }

  .main-card-header{
    font-size: 25px;
    height: 50px;
  }

  .main-card-body{
    background-size: 150px;
  }

}

.main-card-header::after {
  content: "";
  display: block;
  border-bottom: 2px solid #135663;
  width: 50%;
  position: relative;
  margin: auto;
  bottom: -6px; /* your padding + border-width */
}


</style>

