<template>
  <div class="main-wrapper">
    <div v-if="$store.state.dataLoaded" class="d-flex flex-column">
      <Header/> 
      <Body/>
      <Footer
        class="navbar navbar-default navbar-static-bottom"
      />
    </div>      
   </div>
</template>

<script>

export default {
  name: "Home",
  data(){
    return {
      showLoginModal: false,
      polling: null,
    };
  },
  async created(){
    this.$store.dispatch('getUserData');
    // start data polling every minute
    this.pollData()
  },
  beforeDestroy(){
    clearInterval(this.polling)
  },
  methods:{	
    pollData () {
      this.polling = setInterval(() => {
          this.$store.dispatch('getUserData');
      }, 60000)
	  }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
