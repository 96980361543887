import { parseStringStyle } from "@vue/shared";

export default {
    fr: {
        message: {
            Hello: 'Bonjour',
            mariageCivil: 'Mariage civil',
            ceremonieLaique: 'Cérémonie Laïque',
            contact:' Contact',
            Close: 'Fermer',
            Connexion: 'Connexion',
            Login: 'Me connecter',
            Logout: 'Me déconnecter',
            LogoutMessage: 'La session a expiré, vous devez vous reconnecter',
            Username: 'Nom d\'utilisateur',
            Password: 'Mot de passe',
            content_admin: 'Page Admin',
            user_admin: 'Page Utilisateurs',
            ContentType: 'Carte',
            Input: 'Input Content',
            Cancel: 'Annuler',
            Save: 'Enregistrer',
            User:'Utilisateur',
            generalInput: 'Contenu pour tous les utilisateur :',
            selectUserCustomInput: 'Selectionnez un utilisateur pour saisir un message personnalisé',
            CustomInput:'Message personnalisé :',
            Language: 'Langue',
            ceremonyDate: 'Date de la cérémonie', 
            ceremonyHours: 'Horaires de la cérémonie',  
            ceremonyAdress: 'Adresse de la cérémonie', 
            hotel:'Hébergement',
            hotelAdress:'Adresse hébergement',
            hotelRoom:'Chambre',
            price: 'Prix',
            hebergement: 'Hébergement :',
            ChooseFile: 'Choisissez un fichier',
            QuickImport: 'Quick Import',
            UploadSuccessful:'# UploadSuccessful',
            SaveSuccessful: 'Enregistré',
            CancelSuccessful: 'Annulé',
            Error: 'Erreur',
            noSelect: 'Aucune sélcetion',
            connectionLost: 'Connection perdue',
            connectionCheck: 'Reconnection en cours...',
            EnterPassword: 'Saississez le mot de passe',
            ReEnterPassword: 'Re-saississez le mot de passe',
            editUser: 'Editer un utilisateur',
            createUser: 'Ajouter un nouvel utilisateur',
            quickUserImport: 'Utilisateur Quick-Import',
            firstname: 'Nom de l\'utilisateur',
            username: 'login name',
            email: 'E-mail',
            changePassword: 'Changer le mot de passe',
            incorrectPassword: 'L\'email ou le mot de passe saisi n\'est pas correct.',
            administrator: 'Administrateur',
            Scanner: 'Image Scanner',
            ScannerAnswer: 'Valeurs détectées',
            WeddingPresent: 'Je confirme notre présence au mariage',  
            AccomodationCared: 'Je confirme le logement proposé ci-dessous',
            AccomodationSelf: 'Je m\'occupe seul de trouver un logement', 
            MusicChoice: 'Vous pouvez entrer ci-dessous, vos souhaits musicaux pour la fête :',
            numberGuests: 'Nombre d\'invités', 
            numberChildren: 'Nombre d\'enfants', 
            pictureGallery: 'Galerie photo du mariage civil :',

        },
    },
    de: {
        message: {
            Hello: 'Hallo',
            mariageCivil: 'Standesamt',
            ceremonieLaique: 'Freie Trauung',
            contact:' Kontakt',
            Close: 'Schließen',
            Connexion: 'Anmeldung',
            Login: 'Anmeldung',
            Logout: 'Abmeldung',
            LogoutMessage: '# La session a expiré, vous devez vous reconnecter',
            Username: 'Benutzername',
            Password: 'Passwort',
            content_admin: 'Adminseite',
            user_admin: 'Userverwaltung',
            ContentType: 'Karte',
            Input: 'Input Content',
            Cancel: 'Abbrechen',
            Save: 'Speichern',
            User:'Benutzer',
            generalInput: 'Inhalt für alle Benutzer:',
            selectUserCustomInput: 'Benutzer auswählen, um eine personalisierte Nachricht einzugeben',
            CustomInput:'Personalisierte Nachricht eingeben:',
            Language: 'Sprache',
            ceremonyDate: 'Datum Zeremonie', 
            ceremonyHours: 'Uhrzeit Zeremonie',  
            ceremonyAdress: 'Adresse Zeremonie',
            hotel:'Übernachtung',
            hotelAdress:'Übernachtungsadresse',
            hotelRoom:'Zimmer',
            price: 'Preis',
            hebergement: 'Unterkunft:',
            ChooseFile: 'Datei auswählen',
            QuickImport: 'Quick Import',
            UploadSuccessful:'# UploadSuccessful',
            SaveSuccessful: 'Gespeichert',
            CancelSuccessful: 'Abgebrochen',
            Error: 'Fehler',
            noSelect: 'Keine Auswahl',
            connectionLost: 'Verbindung verloren',
            connectionCheck: 'Versucht die Verbindung neu aufzubauen...',
            EnterPassword: 'Passwort eingeben',
            ReEnterPassword: 'Passwort neu-eingeben',
            editUser: 'Benutzer bearbeiten',
            createUser: 'Benutzer hinzufügen',
            quickUserImport: 'Benutzer Schnellimport',
            firstname: 'Benutzername',
            username: 'Anmeldename',
            email: 'E-mail',
            changePassword: 'Passwort Ändern',
            incorrectPassword: 'Der E-Mail oder Passwort ist ungültig.',
            administrator: 'Administrator',
            Scanner: 'Image Scanner',
            ScannerAnswer: 'Scanner Werte',
            WeddingPresent: 'Ich bestätige unsere Teilnahme an der Hochzeit',  
            AccomodationCared: 'Ich bestätige die unten genannte Unterkunft',
            AccomodationSelf: 'Ich kümmere mich selbst um eine Unterkunft', 
            MusicChoice: 'Hier könnt ihr eure Musikwünsche für die Party eingeben:',
            numberGuests: 'Anzahl Gäste', 
            numberChildren: 'Anzahl Kinder', 
            pictureGallery: 'Bildergalerie der standesamtlichen Hochzeit:',

        },
    },
}