<template>
  <div class="main-wrapper">
    <!--<LoginModal v-if="$store.state.showLoginModal" @close-modal="$store.commit('closeLoginModal')"/>-->
    <!--<LoginModal v-if="showLoginModal"/>--> 
    <div v-if="showLogin" class="modal-mask">
      <div class="modal-wrapper d-flex justify-content-center align-items-center py-5 " >
        <div class="card w-35">
          <div class="card-body">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h4 class="card-title text-center mb-2 mt-1">{{ $t("message.Connexion") }}</h4>
            </div>
            <hr class="m-3">
            <!--<p class="text-success text-center">Some message goes here</p>-->
            <form>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text"> <i class="fa fa-user"></i> </span>
                  </div>
                  <input required name="" class="form-control" type="text" v-model="username" :placeholder="$t('message.Username')" autofocus @keyup.enter="Login()">
                </div> <!-- input-group.// -->
              </div> <!-- form-group// -->
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                  </div>
                    <input required class="form-control" type="password" v-model="password" :placeholder="$t('message.Password')" @keyup.enter="Login()">
                </div> <!-- input-group.// placeholder="******"  -->
              </div> <!-- form-group// -->
              <div class="form-group">
                <button submit class="btn btn-primary btn-block" @click.prevent="login()"> {{ $t("message.Login") }}  </button>
              </div> <!-- form-group// -->
            </form> 
          </div>
        </div>
      </div>
    </div>
   </div>
</template>

<script>

export default {
  name: "AuthLayout",
  data(){
    return {
        showLogin: false,
        username: null,
        password: null,
    };
  },
  async created(){
    let token = await this.$store.dispatch('refreshToken'); 
    
    if(token){
        this.$router.push('/home')
    }else{
        this.showLogin  = true
    }
  },
  methods:{
    async login(){
        await this.$store.dispatch('obtainToken',{'username': this.username.toLowerCase(),'password': this.password.toLowerCase()})
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
