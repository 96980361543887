<template>
  <div class="body-wrapper">

    <agile :dots="false" :infinite="false" :center-mode="true" :nav-buttons="false" :swipe-distance="noSweep ? 1000000000 : 50" @after-change="setCurrentSlide($event)" ref="carousel" >
      <div v-for="(card, index) in cards" class="slide d-flex justify-content-center mb-4" :key="index">

        <CardScanner
          v-if="card.card_type==='scanner'"
          :data="card"
        />

      </div>
    </agile>
   
    <button v-if="currentslide > 0" id="#prevButton" class="agile__nav-button agile__nav-button--prev responsive-agile-button" @click="$refs.carousel.goToPrev()" type="button" style="box-shadow:none;"><i class="fas fa-chevron-left responsive-agile-button"></i></button>
    <button v-if="currentslide < cardDockSize-1" id="#nextButton" class="agile__nav-button agile__nav-button--next responsive-agile-button" @click="$refs.carousel.goToNext()" type="button" style="box-shadow:none;"><i class="fas fa-chevron-right responsive-agile-button"></i></button>
    
    <div v-if="currentslide > 0" id="mobile-show-left" class="agile__nav-button agile__nav-button--prev responsive-agile-button-mobile-left hidden" @click="$refs.carousel.goToPrev()"></div>
    <div v-if="currentslide < cardDockSize-1" id="mobile-show-right" class="agile__nav-button agile__nav-button--next responsive-agile-button-mobile-right hidden" @click="$refs.carousel.goToNext()"></div>

  </div>
</template>

<script>
import CardScanner from './CardScanner.vue'

export default {
  name: "ScBody",
  components:{
    CardScanner
  },
  data() {
    return {
      currentslide: 0,
      noSweep: false,
      timeoutLeft: null,
      timeoutRight: null,
      allowClickOnLeft: true,
      allowClickOnRight: true,
    }
  },
  computed:{
    cards(){
      let cards = [{
        card_type: 'scanner',
        card_title: 'message.Scanner'
      }]
      return cards
    },
    cardDockSize(){
      let arrayLength = 0
      if(this.$store.state.isAdmin){
        arrayLength = this.cards.length
      }else{
        let noAdminCards = this.cards.filter(e => (e.card_type !== 'user_admin' && e.card_type !== 'content_admin' )) 
        arrayLength = noAdminCards.length
      }
    
      return arrayLength

    }
  },
  beforeMount () {
  	window.addEventListener('keydown', this.handleKeydown, null);
    window.addEventListener('touchstart', this.handleTouch, {passive:false} );
  },
  beforeDestroy () {
  	window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('touchstart', this.handleTouch);
  },
  methods:{ 
    handleKeydown (e) {
      let vm = this
    	switch (e.keyCode) {
        case 37:
         vm.$refs.carousel.goToPrev(); 
         break;
         case 39: 
         vm.$refs.carousel.goToNext(); 
         break;
      }
    },
    setCurrentSlide(){
      this.currentslide = this.$refs.carousel.getCurrentSlide(); 
    },
    showElement(e){
      if(e && e.classList.contains("hidden")){
        e.classList.add("visible");
        e.classList.remove("hidden");
      }
    },
    hideElement(e){
      if(e && !e.classList.contains("hidden")){
          e.classList.remove("visible");
          e.classList.add("hidden");
      }
    },
    disableClickOnLeft(){
      let vm = this
      if(vm.allowClickOnLeft){
        vm.allowClickOnLeft = false
        setTimeout(function(){
            vm.allowClickOnLeft = true
        }, 3000);
      }
    },
    disableClickOnRight(){
      let vm = this
      if(vm.allowClickOnRight){
        vm.allowClickOnRight = false
        setTimeout(function(){
            vm.allowClickOnRight = true
        }, 3000);
      }
    },
    handleTouch (e) {
      let vm = this;
      if(e.type == 'touchstart'){
          // detect left or right from screen
          let leftZone = {
            min: 0,
            max: Math.floor(window.screen.width/2)
          }
          let rightZone = {
            min: Math.floor(window.screen.width/2)+1,
            max: window.screen.width
          }

          let leftElement = document.getElementById('mobile-show-left')
          let rightElement = document.getElementById('mobile-show-right')

          let touch = e.touches[0]
          if(touch){
            let x = touch.pageX;
            let coef = 0.5

            // Click Left 
            if(x > leftZone.min && x <= Math.floor(leftZone.max*coef)){
                vm.showElement(leftElement)
                vm.hideElement(rightElement)

            // Click right 
            }else if(x > Math.floor(rightZone.min*(1+(coef))) && x <= rightZone.max){
                vm.showElement(rightElement)
                vm.hideElement(leftElement)

            // Click Middle 
            }else{
                vm.hideElement(rightElement)
                vm.hideElement(leftElement)
            }
          }
      } 
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.body-wrapper{
  padding-top: 35px;
}

button{
    background-color: transparent;
    cursor: pointer;
    display: block;
    height: 10px;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    transition-duration: .5s;
    width: 10px;
    outline: none;
}

.agile__nav-button{
  background: transparent;
  border: none;
  color: rgb(110, 108, 108);
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: .5s;
  width: 80px;
}


.agile__nav-button--prev {
  height: 100%;
  left:0;
  z-index: 1;
}

.agile__nav-button--next {
  height: 100%;
  right:0;
  z-index: 1;
}

@media only screen and (max-width:400px) {
  .body-wrapper{
    padding-top: 0px;
  }

.agile__nav-button:hover{
    background-color: rgba(rgb(255, 255, 255), .5);
    opacity: 1;
}

.agile__nav-button--prev::before {
  position: absolute;
  height: 100%;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, rgb(109, 109, 109,1) 0%, rgba(109, 109, 109, 0.05) 90%, rgba(109, 109, 109, 0) 100%);
  z-index: -1;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.agile__nav-button--prev:hover::before {
  opacity: 1;
}


.agile__nav-button--next::before {
  position: absolute;
  height: 100%;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to left, rgba(109, 109, 109, 1) 0%, rgba(109, 109, 109, 0.05) 90%, rgba(109, 109, 109, 0) 100%);
  z-index: -1;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.agile__nav-button--next:hover::before {
  opacity: 1;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}


 .responsive-agile-button{
    display: none;
  }

  .responsive-agile-button-mobile-left{
    height: 100%;
    width: 25px;
    background: linear-gradient(to right, rgb(107, 107, 107, 0.5) 0%, rgba(107, 107, 107, 0.05) 90%, rgba(107, 107, 107, 0) 100%);
  }

  .responsive-agile-button-mobile-right{
    height: 100%;
    width: 25px;
    background: linear-gradient(to left, rgba(107, 107, 107, 0.5) 0%, rgba(107, 107, 107, 0.05) 90%, rgba(107, 107, 107, 0) 100%);
  }

}


</style>

