import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import store from '../store/index.js'

// Pages
import Home from '@/pages/Home';
import AuthLayout from '@/pages/AuthLayout';
import ScannerHome from '@/pages/ScannerHome';
import PageNotFound from '@/pages/PageNotFound';

const authPages = {
  path: "/auth",
  component: AuthLayout,
  name: "Authentication",
  meta: {
      guest: true
  },
};

const routes = [
  authPages,
  {
    path: "/home",
    component: Home,
    routeAlias: "Home",
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/scanner",
    component: ScannerHome,
    routeAlias: "ScannerHome",
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/api/*", 
  },
  { 
    path: "/:catchAll(.*)", component: PageNotFound 
  } 
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/auth'];
  const authRequired = !publicPages.includes(to.path);
  const isAuth = store.state.isAuthenticated;
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !isAuth) {
    next('/auth');
  } else {
    next();
  }
});

export default router
