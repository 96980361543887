import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'

// https://github.com/lukaszflorczak/vue-agile
import VueAgile from 'vue-agile'

// asset imports
import "./assets/css/style.css";
import "./assets/css/fontawsome.css";

// Global components
import Header from './components/Header.vue'
import Body from './components/Body.vue'
import Footer from './components/Footer.vue' 
import Card from './components/Card.vue' 
import CardContentAdmin from './components/CardContentAdmin.vue'
import CardUserAdmin from './components/CardUserAdmin.vue' 
import LoginModal from './components/modals/LoginModal.vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'; 


// i18n
import messages from './messages.js';

const i18n = createI18n({
    // something vue-i18n options here ...
    locale: navigator.language, // set locale
    fallbackLocale: 'de',
    messages,
    silentTranslationWarn: true, // Disable warn messages on Console
  })

const app = createApp(App).use(store).use(router).use(i18n)

store.$t = i18n.global.t;
app.component('Header',Header)
app.component('Body',Body)
app.component('Footer',Footer) 
app.component('Card',Card) 
app.component('CardContentAdmin',CardContentAdmin)
app.component('CardUserAdmin',CardUserAdmin)
app.component('LoginModal',LoginModal)
app.component('QuillEditor', QuillEditor)
app.component('Cropper', Cropper)

app.use(VueAgile)

app.mount('#app')

