<template>
    <div class="d-flex align-items-end justify-content-center noselect">
      {{ $store.state.firstName }} - Copyright© Gabriele & Francois {{ new Date().getFullYear() }} &nbsp; 
      <router-link v-if="$store.state.isAdmin" to="/Scanner">Scanner</router-link> &nbsp; 
      <a class="hyperlink" @click="$store.dispatch('logout',true)">{{ $t('message.Logout') }}</a>
    </div>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

