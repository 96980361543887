<template>
    <div class="main-title d-flex justify-content-center noselect">
      Gabriele & François
    </div>
</template>

<script>
import api from "@/api";

export default {
  name: "Header",
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-title{
padding-top: 35px;
 font-family: 'BauerBodoni';
 font-size: 70px;
 color: #993300;
}

@media only screen and (max-width:400px) {
  .main-title{
    padding-top: 20px;
    font-size: 35px;
  }
}


</style>

