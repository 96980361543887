<template>
  <div class="main-wrapper">
    <div class="inner-wrapper">
      <div class="landscape"></div>
    </div>  
    <LoginModal v-if="$store.state.showLoginModal" @close-modal="$store.commit('closeLoginModal')"/>
      
    <div v-if="$store.state.isAuthenticated && $store.state.dataLoaded" class="d-flex flex-column">
      <ScHeader/> 
      <ScBody/>
      <ScFooter
        class="navbar navbar-default navbar-static-bottom"
      />

    </div>      
  </div>
</template>

<script>
import ScHeader from '../components/Scanner/ScHeader.vue'
import ScBody from '../components/Scanner/ScBody.vue'
import ScFooter from '../components/Scanner/ScFooter.vue'

export default {
  name: "ScannerHome",
  components:{
    ScHeader,
    ScBody,
    ScFooter,
  },
  data(){
    return {
      showLoginModal: false,
      polling: null,
    };
  },
  watch:{
    authenticationChange(){
      if(this.$store.state.isAuthenticated){
        this.$store.dispatch('getUserData');
      }
    }
  },
  computed: {
    authenticationChange () {
      return this.$store.state.isAuthenticated
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-wrapper{
  font-family:Monospace;
  font-size:14px;
  line-height:1.428571429;
  color: var(--neon-main-color);
}

.inner-wrapper {
  background: var(--neon-bg-color);
  width: 100vw;
  height: 100vh;
  position: absolute;
  margin: 0 auto;
  perspective: 180px;
  perspective-origin: 50% 40%;
}

 
</style>
