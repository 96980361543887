<template>          
  <div class="main-card noselect">
    <div class="main-card-header">
      <span>Image Scanner</span>
    </div>
      
    <div class="main-card-body" >
      
        <!-- open camera -->
        <div v-if="!isLoading && !imageReady" class="d-flex justify-content-center">
          <button class="sc-button block" style="">  
            <input id="fileInput" ref="imageUploader" class="custom-file-input file-input-button" type="file" @click="resetFileUploader(event)" @change="uploadFile( $event )">
            <i class="fas fa-camera"></i>
          </button>
        </div>

        <!-- Spinner -->
        <div v-if="isLoading" class="wrapper d-flex flex-row justify-content-center mb-4">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <!-- Preview image -->
        <div class="wrapper d-flex flex-column">
          <div class="d-flex flex-row justify-content-center">
          
            <!-- Rotate Image -->
            <!--<div v-if="!isLoading && imageReady && !isDataReady" class="d-flex justify-content-center">
              <button class="sc-button" @click="rotateImage(-90)" >  
                <i class="fas fa-undo"></i>
              </button>
            </div>-->
          </div>

          <!-- image -->
          <div id="image-container" v-if="!isLoading && imageReady && !isDataReady" class="wrapper d-flex justify-content-center">
              <!--<img v-if="!isCrop" id="preview-image" :src="imageUrl" class="rounded" alt="displayed image" style="width: 100%; height: auto;">-->
              <cropper
                id="preview-image"
                class="cropper"
                :src="imageUrl"
                :default-size="defaultSize"
                @change="change"
	              ref="cropper"
              ></cropper>
          </div>

        </div>
        
        <!-- Text-Output -->
        <div v-if="!isLoading && !imageReady && isDataReady" class="wrapper mt-3">
            <div class="sc-content-output p-2 mx-1" style="white-space:pre;">{{ content }}</div>
        </div>

        <!-- Cancel - Send Image - Copy -->
        <div class="d-flex flex-row justify-content-center mt-3">
          <div v-if="isDataReady || imageReady" class="d-flex flex-row justify-content-center">
            <button class="btn sc-button mb-2 mr-2 p-2" @click="Cancel()">Cancel</button>
          </div>
          <!-- Send Image -->
          <div v-if="!isLoading && imageReady && !isDataReady" class="d-flex justify-content-center ml-3">
            <button class="btn sc-button mb-2 mr-2 p-2" @click="sendFile()" >  
              Scan it!
            </button>
          </div>
          <!-- Copy to clipboard -->
          <div v-if="isDataReady" class="d-flex flex-row justify-content-center ml-3">
            <button class="btn sc-button mb-2 mr-2 p-2" @click="Clipboard()">
              <i class="far fa-clipboard p-2"></i>
            </button>
          </div>
        </div>
        
    </div>
  </div>
</template>

<script>
// source: https://dev.to/ditarahma08/make-a-camera-app-in-web-part-2-capturing-the-image-289i

// https://github.com/fengyuanchen/cropperjs#methods

import api from "@/api";

export default {
  name: "CardUserAdmin", 
  props: {
    data: Object,
  },
  data() {
    return {
      isLoading :false,
      isDataReady: false,
      imageReady: false,
      cropCoordinates: null,
      imageUrl: null,
      fileName: '',
      imageAngle: 0,
      editor: null,
      content: null,
    };
  },
  computed:{
  },
  methods:{
    resetFileUploader() {
      this.$refs.imageUploader.value = '';
    },
    uploadFile( event ){
      let vm = this
      this.Cancel(); // reset all before new image
      const file = event.target.files[0];
      vm.imageUrl = URL.createObjectURL(file);
      vm.fileName = file.name
      vm.imageReady = true;

    },
    rotateImage(angle){

			this.$refs.cropper.rotate(angle);
		
      if(angle<0){
        if( this.imageAngle + angle > -180 && this.imageAngle + angle <= 0 ){
          this.imageAngle = this.imageAngle + angle 
        }else{
          this.imageAngle = this.imageAngle + angle + 360
        }
      }else{
        if( this.imageAngle + angle <= 180 && this.imageAngle + angle >= 0 ){
          this.imageAngle = this.imageAngle + angle 
        }else{
          this.imageAngle = this.imageAngle + angle - 360
        }
      }
 

    },
    async sendFile( event ){
      let vm = this
      vm.isLoading = true;
      vm.imageReady = false;
      
      let formData = new FormData();
      let file = await fetch(vm.imageUrl).then(r => r.blob()).then(blobFile => new File([blobFile], vm.fileName, { type: "image/png" }))
      formData.append('file', file );
      formData.append('description', 'image_scanner');
      formData.append('image-angle', vm.imageAngle);
      formData.append('crop-coordinates', JSON.stringify(vm.cropCoordinates));

      const token = await vm.$store.dispatch('getToken');	
      
			const url = '/v1/image_scanner/'
			await api.post(url, formData, 
				{headers: {
          Authorization: `Bearer ${token.access}`,
          'Content-Type': 'multipart/form-data'
        }})

				.then((response)=>{
					//console.log(response.data.content)
          vm.content = response.data.content
          vm.isLoading = false;
          vm.isDataReady = true;
				})
				.catch((error)=>{
					//console.log('uploadFile',error)
          vm.isLoading = false;
				})
      
      vm.imageUrl = null
      vm.fileName = ''

    },
    Cancel(){
      this.isDataReady = false
      this.isLoading = false
      this.content = null
      this.imageReady = false
      this.imageUrl = null
      this.imageAngle = 0
      this.fileName = ''

      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    Clipboard(){
      navigator.clipboard.writeText(this.content)
    },
    change({coordinates, canvas}) {
      //console.log(coordinates, canvas)
      this.cropCoordinates = coordinates
    },
    defaultSize({ imageSize, visibleArea }) {
			return {
				width: (visibleArea || imageSize).width,
				height: (visibleArea || imageSize).height,
			};
		}
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.main-card{

  font-family:Monospace;
  font-size:14px;
  line-height:1.428571429;
  color: var(--neon-main-color);

  border-radius: 25px;
  border :solid var(--neon-main-color);
  padding: 20px;
  margin:20px;
  width: 800px;
  min-height: 500px;
}

.main-card-header{
  font-family:Monospace;
  font-size: 40px;
  text-align: center;
  height: 80px;
  line-height:1.428571429;
  color: var(--neon-main-color);
}

.main-card-body{
   height: 80%;
   padding-top:15px;
}

.cropper {
  height: 500px;
  background: #DDD;
}

@media only screen and (max-width:500px) {

  .main-card{
    margin: 25px;
    margin-top: 15px;
    padding: 10px;
    margin:10px;
    width: 90%;
  }

  .main-card-header{
    font-size: 25px;
    height: 50px;
  }

  .main-card-body{
    background-size: 150px;
  }

}

.main-card-header::after {
  content: "";
  display: block;
  border-bottom: 2px solid var(--neon-main-color);
  width: 50%;
  position: relative;
  margin: auto;
  bottom: -6px; /* your padding + border-width */
}

.custom-file-upload {
    border: 1px solid var(--neon-main-color);
    display: inline-block;
    padding: 6px 12px;
}

.file-input-button{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer !important;
}

.sc-content-output{
  color: var(--neon-maincolor);
  background-color: var(--neon-bg-color);
  border: solid 2px var(--neon-main-color);
  border-radius: 5px;
}

</style>

